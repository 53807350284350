import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import eventBus from '../../../utils/eventBus';
import ListItem from './components/ListItem';
import StatisticsWidget4 from '../../../_metronic/partials/widgets/customized/StatisticsWidget4';
import StatisticsWidget5 from '../../../_metronic/partials/widgets/customized/StatisticsWidget5';
import StatisticsWidget6 from '../../../_metronic/partials/widgets/customized/StatisticsWidget6';
import ApexChartInit from './components/ApexChart';
// import StockHolding from './components/StockHolding';
import { getDashboardData, getExchangeRateSetting } from './service';
// import { currencyFormater } from '../../../utils/utils';
import {
	moneyFormaterWithoutCurrencyPrefix,
	growAndDecreaseRenderer,
} from '../../../utils/tableColumnUtils';
import { stockHoldingRenderer, getCardLinkRenderer } from './utils';
import './dashboard.scss';

const DashboardPage = ({
	data,
	stockHoldingData,
	goldHoldingData,
	taskData,
	exchangeRate,
}) => (
	<div className="waterfall">
		<div className="card card-customized shadow-sm">
			<div className="card-header">
				<h3 className="card-title">资金情况</h3>
				{getCardLinkRenderer('/user-management/user-list')}
			</div>
			<div className="card-body">
				<ListItem
					icon="finance/fin008"
					iconColor="info"
					title="用户总资产"
					numberRenderer={
						<>
							<span>
								{moneyFormaterWithoutCurrencyPrefix(data.total_asset)}
							</span>
							{data.total_asset !== undefined && (
								<span> ={data.total_asset * exchangeRate}(CNY)</span>
							)}
						</>
					}
				/>
				<ListItem
					icon="wallet"
					iconColor="info"
					iconType="keenicon"
					iconPathCount={4}
					title="可用资产"
					numberData={data.available}
				/>
				<ListItem
					icon="general/gen051"
					iconColor="info"
					title="冻结资产"
					numberData={data.frozen}
				/>
				<ListItem
					icon="two-credit-cart"
					iconColor="info"
					iconType="keenicon"
					iconPathCount={5}
					title="存款金额"
					numberData={data.total_deposits}
				/>
				<ListItem
					icon="two-credit-cart"
					iconColor="info"
					iconType="keenicon"
					iconPathCount={5}
					title="取款金额"
					numberData={data.total_withdrawals}
				/>
				<ListItem
					icon="verify"
					iconColor="info"
					iconType="keenicon"
					iconPathCount={2}
					title="净入金"
					numberData={data.net_income}
				/>
				<ListItem
					icon="financial-schedule"
					iconColor="info"
					iconType="keenicon"
					iconPathCount={4}
					title="盈亏"
					numberData={data.earnings}
				/>
				<ListItem
					icon="two-credit-cart"
					iconColor="info"
					iconType="keenicon"
					iconPathCount={5}
					title="人工转入"
					numberData={data.manual_increase}
				/>
				<ListItem
					icon="two-credit-cart"
					iconColor="info"
					iconType="keenicon"
					iconPathCount={5}
					title="人工转出"
					numberData={data.manual_decrease}
				/>
			</div>
		</div>

		{/* <div className="card card-customized shadow-sm">
			<div className="card-header">
				<h3 className="card-title">合约情况</h3>
				{getCardLinkRenderer('/order-management/contract-list')}
			</div>
			<div className="card-body">
				<ListItem
					icon="finance/fin003"
					title="总操盘资金"
					numberData={data.balance}
				/>
				<ListItem
					icon="finance/fin002"
					title="保证金金额"
					numberData={data.base_amount}
				/>
				<ListItem
					icon="finance/fin002"
					title="总配资金额"
					numberData={data.loan_amount}
				/>
				<ListItem
					icon="finance/fin010"
					title="合约总值"
					numberData={data.margin_total_asset}
				/>
				<ListItem
					icon="financial-schedule"
					iconType="keenicon"
					iconPathCount={4}
					title="合约总盈亏"
					numberRenderer={
						<>
							<span>
								{moneyFormaterWithoutCurrencyPrefix(data.margin_total_earnings)}
							</span>
							<span>
								{moneyFormaterWithoutCurrencyPrefix(
									data.margin_total_earnings_percent,
									'%',
									'',
									4
								)}
							</span>
						</>
					}
				/>
				<ListItem
					icon="chart-line-star"
					iconType="keenicon"
					iconPathCount={3}
					title="持仓市值"
					numberRenderer={
						<>
							<span>
								{moneyFormaterWithoutCurrencyPrefix(data.holding_vol)}
							</span>
							<span>
								{moneyFormaterWithoutCurrencyPrefix(data.holding_vol_add)}
								{moneyFormaterWithoutCurrencyPrefix(
									data.holding_vol_percent,
									'%)',
									'('
								)}
							</span>
						</>
					}
				/>
				<ListItem
					icon="verify"
					iconType="keenicon"
					iconPathCount={2}
					title="合约提盈"
					numberData={data.transfer_margin_withdrawal}
				/>
				<ListItem
					icon="chart-pie-3"
					iconType="keenicon"
					iconPathCount={3}
					title="合约总利息"
					numberData={data.transfer_interest}
				/>
				<ListItem
					icon="chart-line"
					iconType="keenicon"
					iconPathCount={2}
					// title="合约总手续费"
					title="合约总利息"
					numberData={data.transfer_fee}
				/>
			</div>
		</div> */}

		<div className="card card-customized shadow-sm">
			<div className="card-header">
				<h3 className="card-title">印尼股票持仓</h3>
				{getCardLinkRenderer('/order-management/hold-list')}
			</div>
			<div className="card-body">
				<ListItem
					icon="dollar"
					iconColor="success"
					iconType="keenicon"
					iconPathCount={3}
					title="持仓市值"
					numberData={stockHoldingData.holding_vol}
				/>
				<ListItem
					icon="finance-calculator"
					iconColor="success"
					iconType="keenicon"
					iconPathCount={7}
					title="总盈亏"
					numberData={stockHoldingData.total_earnings}
				/>
				<ListItem
					icon="financial-schedule"
					iconColor="success"
					iconType="keenicon"
					iconPathCount={4}
					title="浮动盈亏"
					numberRenderer={
						<>
							<span>
								{moneyFormaterWithoutCurrencyPrefix(
									stockHoldingData.float_earnings
								)}
							</span>
							<span>
								{/* {moneyFormaterWithoutCurrencyPrefix(
									stockHoldingData.float_earnings_percent
								)}
								% */}
								{growAndDecreaseRenderer(
									stockHoldingData.float_earnings_percent
								)}
							</span>
						</>
					}
				/>
				<ListItem
					icon="calendar"
					iconColor="success"
					iconType="keenicon"
					iconPathCount={2}
					title="当日盈亏"
					numberData={stockHoldingData.total_daily_earnings}
				/>
			</div>
		</div>

		<div className="card card-customized shadow-sm">
			<div className="card-header">
				<h3 className="card-title">印尼黄金持仓</h3>
				{getCardLinkRenderer('/order-management/gold-hold-list')}
			</div>
			<div className="card-body">
				<ListItem
					icon="dollar"
					iconColor="success"
					iconType="keenicon"
					iconPathCount={3}
					title="持仓市值"
					numberData={goldHoldingData.holding_vol}
				/>
				<ListItem
					icon="finance-calculator"
					iconColor="success"
					iconType="keenicon"
					iconPathCount={7}
					title="总盈亏"
					numberData={goldHoldingData.total_earnings}
				/>
				<ListItem
					icon="financial-schedule"
					iconColor="success"
					iconType="keenicon"
					iconPathCount={4}
					title="浮动盈亏"
					numberRenderer={
						<>
							<span>
								{moneyFormaterWithoutCurrencyPrefix(
									goldHoldingData.float_earnings
								)}
							</span>
							<span>
								{/* {moneyFormaterWithoutCurrencyPrefix(
									goldHoldingData.float_earnings_percent
								)}
								% */}
								{growAndDecreaseRenderer(
									goldHoldingData.float_earnings_percent
								)}
							</span>
						</>
					}
				/>
				<ListItem
					icon="calendar"
					iconColor="success"
					iconType="keenicon"
					iconPathCount={2}
					title="当日盈亏"
					numberData={goldHoldingData.total_daily_earnings}
				/>
			</div>
		</div>

		{/* <div className="card card-customized shadow-sm">
			<div className="card-header">
				<h3 className="card-title">交易情况</h3>
				<ul className="nav nav-tabs nav-pills border-0 flex-row mb-3 mb-md-0">
					<li className="nav-item me-0">
						<a
							className="nav-link active"
							data-bs-toggle="tab"
							href="#kt_tab_pane_trade_1"
						>
							今日
						</a>
					</li>
					<li className="nav-item">
						<a
							className="nav-link"
							data-bs-toggle="tab"
							href="#kt_tab_pane_trade_2"
						>
							近一周
						</a>
					</li>
					<li className="nav-item">
						<a
							className="nav-link"
							data-bs-toggle="tab"
							href="#kt_tab_pane_trade_3"
						>
							近一个月
						</a>
					</li>
				</ul>
			</div>
			<div className="card-body">
				<div className="tab-content">
					<div
						className="tab-pane fade show active"
						id="kt_tab_pane_trade_1"
						role="tabpanel"
					>
						{data.transaction && (
							<>
								<ListItem
									icon="tablet-text-up"
									iconColor="warning"
									iconType="keenicon"
									iconPathCount={2}
									title="订单数"
									numberData={data.transaction.day.order_counts}
									chartData={data.transaction.day.order_axis}
									fractionDigits={0}
								/>
								<ListItem
									icon="finance/fin010"
									iconColor="warning"
									title="交易额"
									numberData={data.transaction.day.amount}
									chartData={data.transaction.day.amount_axis}
								/>
								<ListItem
									icon="chart-line"
									iconColor="warning"
									iconType="keenicon"
									iconPathCount={2}
									// title="手续费"
									title="利息"
									numberData={data.transaction.day.fee}
									chartData={data.transaction.day.fee_axis}
								/>
							</>
						)}
					</div>
					<div
						className="tab-pane fade"
						id="kt_tab_pane_trade_2"
						role="tabpanel"
					>
						{data.transaction && (
							<>
								<ListItem
									icon="tablet-text-up"
									iconColor="warning"
									iconType="keenicon"
									iconPathCount={2}
									title="订单数"
									numberData={data.transaction.week.order_counts}
									chartData={data.transaction.week.order_axis}
									fractionDigits={0}
								/>
								<ListItem
									icon="finance/fin010"
									iconColor="warning"
									title="交易额"
									numberData={data.transaction.week.amount}
									chartData={data.transaction.week.amount_axis}
								/>
								<ListItem
									icon="chart-line"
									iconColor="warning"
									iconType="keenicon"
									iconPathCount={2}
									// title="手续费"
									title="利息"
									numberData={data.transaction.week.fee}
									chartData={data.transaction.week.fee_axis}
								/>
							</>
						)}
					</div>
					<div
						className="tab-pane fade"
						id="kt_tab_pane_trade_3"
						role="tabpanel"
					>
						{data.transaction && (
							<>
								<ListItem
									icon="tablet-text-up"
									iconColor="warning"
									iconType="keenicon"
									iconPathCount={2}
									title="订单数"
									numberData={data.transaction.month.order_counts}
									chartData={data.transaction.month.order_axis}
									fractionDigits={0}
								/>
								<ListItem
									icon="finance/fin010"
									iconColor="warning"
									title="交易额"
									numberData={data.transaction.month.amount}
									chartData={data.transaction.month.amount_axis}
								/>
								<ListItem
									icon="chart-line"
									iconColor="warning"
									iconType="keenicon"
									iconPathCount={2}
									// title="手续费"
									title="利息"
									numberData={data.transaction.month.fee}
									chartData={data.transaction.month.fee_axis}
								/>
							</>
						)}
					</div>
				</div>
			</div>
		</div> */}

		{/* <div className="card card-customized shadow-sm">
			<div className="card-header">
				<h3 className="card-title">股票持股榜</h3>
				<ul className="nav nav-tabs nav-pills border-0 flex-row mb-3 mb-md-0">
					<li className="nav-item me-0">
						<a
							className="nav-link active"
							data-bs-toggle="tab"
							href="#kt_tab_pane_holding_1"
						>
							今日
						</a>
					</li>
					<li className="nav-item">
						<a
							className="nav-link"
							data-bs-toggle="tab"
							href="#kt_tab_pane_holding_2"
						>
							近一周
						</a>
					</li>
					<li className="nav-item">
						<a
							className="nav-link"
							data-bs-toggle="tab"
							href="#kt_tab_pane_holding_3"
						>
							近一个月
						</a>
					</li>
				</ul>
			</div>
			<div className="card-body">
				<div className="tab-content">
					<div
						className="tab-pane fade show active"
						id="kt_tab_pane_holding_1"
						role="tabpanel"
					>
						{stockHoldingRenderer(data.stock_data, 'day')}
					</div>
					<div
						className="tab-pane fade"
						id="kt_tab_pane_holding_2"
						role="tabpanel"
					>
						{stockHoldingRenderer(data.stock_data, 'week')}
					</div>
					<div
						className="tab-pane fade"
						id="kt_tab_pane_holding_3"
						role="tabpanel"
					>
						{stockHoldingRenderer(data.stock_data, 'month')}
					</div>
				</div>
			</div>
		</div> */}

		<StatisticsWidget4
			className="card-xl-stretch mb-xl-8"
			data={[
				{
					title: '用户数量',
					count: data.user_nums || 0,
				},
				{
					title: '7日内活跃用户数量',
					count: data.seven_uv
						? data.seven_uv.y.reduce(
								(accumulator, item) => accumulator + item,
								0
						  )
						: 0,
				},
			]}
			chart={
				data.seven_uv ? (
					<ApexChartInit
						color="primary"
						height={100}
						name="7日内活跃用户数量"
						xAxisData={data.seven_uv && data.seven_uv.x}
						yAxisData={data.seven_uv && data.seven_uv.y}
					/>
				) : (
					''
				)
			}
		/>

		<StatisticsWidget5
			className="card-xl-stretch mb-xl-8"
			svgIcon="communication/com014.svg"
			data={[
				{
					title: '代理数量',
					count: data.agent_nums,
				},
				{
					title: '系统管理员数量',
					count: data.sys_nums,
				},
				{
					title: '客服管理员数量',
					count: data.cus_nums,
				},
			]}
		/>

		<StatisticsWidget6
			className="card-xl-stretch mb-xl-8"
			svgIcon="general/gen059.svg"
			data={[
				{
					title: '用户审核',
					count: taskData.user_audit_task,
				},
				{
					title: '存款',
					count: taskData.deposit_task,
				},
				{
					title: '取款',
					count: taskData.withdrawal_task,
				},
			]}
		/>
	</div>
);

DashboardPage.propTypes = {
	data: PropTypes.object,
	stockHoldingData: PropTypes.object,
	goldHoldingData: PropTypes.object,
	taskData: PropTypes.object,
	exchangeRate: PropTypes.number,
};

const DashboardWrapper = () => {
	const intl = useIntl();
	const [dashboardData, setDashboardData] = useState({});
	const [stockHoldingData, setStockHoldingData] = useState({});
	const [goldHoldingData, setGoldHoldingData] = useState({});
	const [taskData, setTaskData] = useState({});
	const [exchangeRate, setExchangeRate] = useState(0);
	const initWaterfall = () => {
		// eslint-disable-next-line no-undef
		new Waterfall({
			containerSelector: '.waterfall',
			boxSelector: '.card',
			minBoxWidth: 350,
		});
	};

	const doGetDashboardData = async () => {
		const resp = await getDashboardData();
		const { indonesia_gold_holding, indonesia_stock_holding, tasks } = resp;
		setDashboardData(resp);
		setStockHoldingData(indonesia_stock_holding);
		setGoldHoldingData(indonesia_gold_holding);
		setTaskData(tasks);
	};

	const doGetExchangeRateSetting = async () => {
		const resp = await getExchangeRateSetting();
		setExchangeRate(
			resp.data.find((item) => item.key === 'cny_idr_exchange_rate').value
		);
	};

	useEffect(() => {
		eventBus.dispatch('bootstrapInit');
		initWaterfall();
		doGetDashboardData();
		doGetExchangeRateSetting();
	}, []);

	return (
		<>
			<PageTitle breadcrumbs={[]}>
				{intl.formatMessage({ id: 'MENU.DASHBOARD' })}
			</PageTitle>
			<DashboardPage
				data={dashboardData}
				stockHoldingData={stockHoldingData}
				goldHoldingData={goldHoldingData}
				taskData={taskData}
				exchangeRate={exchangeRate}
			/>
		</>
	);
};

export { DashboardWrapper };
