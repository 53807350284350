import request from '../../../utils/request';

export async function getDashboardData() {
	return request('/home_page', {
		method: 'get',
	});
}

export async function getExchangeRateSetting() {
	return request('/system_setting', {
		method: 'get',
		params: {
			setting_type: 'EXCHANGE_RATE',
		},
	});
}
